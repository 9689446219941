import { render, staticRenderFns } from "./meeting-points-list.vue?vue&type=template&id=56207683&"
import script from "./meeting-points-list.vue?vue&type=script&lang=js&"
export * from "./meeting-points-list.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports