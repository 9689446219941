import { api } from "./index";

export const getAllMeetingPoints = () => {
  return new Promise((resolve, reject) => {
    api
      .get("meeting_points")
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};

export const getMeetingPoint = (id) => {
  return new Promise((resolve, reject) => {
    api
      .get(`meeting_points/${id}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
}

export const createMeetingPoint = (formData) => {
  return new Promise((resolve, reject) => {
    api
      .post(`meeting_points`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};

export const updateMeetingPoint = (id, formData) => {
  formData.append("_method", "PUT");
  return new Promise((resolve, reject) => {
    api
      .post(`meeting_points/${id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  })
}

export const deleteMeetingPoint = (id) => {
  return new Promise ((resolve, reject) => {
    api 
      .delete(`meeting_points/${id}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
}