<template>
  <b-card-code :title="$t('meeting_point.meeting_points_title')">
    <b-button
      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
      variant="outline-primary"
      class="mt-0 mt-md-2"
      @click="addMeeting()"
    >
      <feather-icon icon="PlusIcon" class="mr-25" />{{
        $t("meeting_point.add_meeting")
      }}
    </b-button>
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">{{ $t("search") }}</label>
          <b-form-input
            v-model="searchTerm"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <vue-good-table
      :columns="columns"
      :rows="meetings_list"
      :search-options="{
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      styleClass="vgt-table table-hover"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'name'" class="row-logo">
          <div>
            <b-img :src="props.row.logo_url" />
          </div>
          <a
            :href="`/meeting_points/${props.row.id}`"
            style="font-weight: bold;"
            v-if="props.row.name != ''"
            >{{ props.row.name }}</a
          >
        </span>

        <span v-if="props.column.field == 'startdate'">
          <span> {{ props.row.start }} </span>
        </span>

        <span v-if="props.column.field == 'enddate'">
          <span> {{ props.row.end }} </span>
        </span>

        <span v-if="props.column.field === 'status'">
          <b-badge pill v-if="props.row.active" variant="success">
            {{ $t("status_active") }}
          </b-badge>
          <b-badge pill v-else variant="danger">
            {{ $t("status_inactive") }}
          </b-badge>
        </span>

        <span v-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="goToEditMeeting(props.row.id)">
                <feather-icon icon="FileTextIcon" class="mr-50" />
                <span>{{ $t("edit") }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteMeeting(props.row.id)">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span> {{ $t("meeting_point.delete") }} </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
      </template>

      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              {{ $t("showing") }}
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap">
              {{ $t("showing_pt2", { total_entries }) }}
            </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BAvatar,
  BBadge,
  BButton,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BImg,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import Ripple from "vue-ripple-directive";

import { getAllMeetingPoints, deleteMeetingPoint } from "@api/meetingpoints.js";

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BImg,
  },
  data() {
    return {
      pageLength: 10,
      searchTerm: "",
      columns: [
        {
          field: "name",
          label: this.$t("field_label_name"),
        },
        {
          field: "startdate",
          label: this.$t("conference.Start"),
        },
        {
          field: "enddate",
          label: this.$t("conference.End"),
        },
        {
          field: "status",
          label: this.$t("field_label_status"),
          sortable: false,
        },
        {
          field: "action",
          label: this.$t("field_label_actions"),
          sortable: false,
        },
      ],
      meetings_list: [],
      total_entries: null,
    };
  },
  directives: {
    Ripple,
  },
  created() {
    this.getData();
  },

  methods: {
    getData() {
      let $this = this;
      getAllMeetingPoints()
        .then((data) => {
          $this.meetings_list = data;
          $this.total_entries = $this.meetings_list.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addMeeting() {
      this.$router.push({ path: `/meeting_points/add` });
    },
    goToEditMeeting(id) {
      this.$router.push({ path: `/meeting_points/${id}` });
    },
    deleteMeeting(id) {
      const $this = this;
      this.$swal({
        title:
          '<span class="font-weight-bolder">' +
          this.$t("alert_title_warning") +
          "</span>",
        icon: "warning",
        text: this.$t("meeting_point.alert_content_text"),
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: this.$t("alert_confirm_button"),
        cancelButtonText: this.$t("alert_cancel_button"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$swal({
            icon: "success",
            title: this.$t("alert_title_success"),
            text: this.$t("meeting_point.alert_success_text"),
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          deleteMeetingPoint(id)
            .then(() => {
              $this.getData();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
  },
};
</script>
